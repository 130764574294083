@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-dark-bg text-dark-text-primary;
    font-family: var(--font-primary);
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-secondary);
  }
}

@layer components {
  .btn {
    @apply px-4 py-3 rounded-lg bg-primary text-white 
           transition-all duration-300 ease-in-out 
           hover:bg-opacity-90 hover:shadow-hover 
           transform hover:scale-105;
  }

  .section-title {
    @apply text-title-main text-dark-text-primary font-bold mb-6;
  }

  .card {
    @apply bg-dark-bg-secondary 
           rounded-lg shadow-dark 
           p-6 transition-all duration-300 ease-in-out 
           hover:shadow-hover-dark;
  }

  .skill-progress {
    @apply h-2 rounded-full bg-dark-border overflow-hidden relative;
  }

  .skill-progress-bar {
    @apply absolute left-0 top-0 h-full bg-primary rounded-full;
    animation: fillProgress 1.5s ease-out forwards;
    width: 0;
  }

  /* Modal Styles */
  .modal-overlay {
    @apply fixed inset-0 bg-black/60;
    animation: fadeIn 0.2s ease-out forwards;
  }

  .modal-content {
    @apply fixed bg-dark-bg-secondary 
           rounded-lg p-8 shadow-dark;
    animation: popupIn 0.3s ease-out forwards;
  }
}

@layer utilities {
  .icon {
    @apply text-[1.2em];
  }

  .text-gradient {
    @apply bg-gradient-to-r from-primary to-secondary 
           bg-clip-text text-transparent;
  }

  .hover-lift {
    @apply transition-transform duration-300 ease-in-out 
           hover:-translate-y-1;
  }

  .animate-spin-once {
    animation: spin 1s ease-in-out;
  }

  .animate-blink {
    animation: blink 0.7s infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes popupIn {
  0% { 
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0;
  }
  100% { 
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes fillProgress {
  0% { width: 0; }
  100% { width: var(--progress, 0%); }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

* {
  scroll-behavior: smooth;
}
